import { computed, onBeforeUnmount, onMounted, ref, watch, type ComputedRef, type Ref } from "vue"

type AnyRef<T> = Ref<T> | ComputedRef<T>

/**
 * Helper method that calls callback when a element outside of the specific list is clicked
 * 
 * @param watchedRefs Array of Vue3 refs that contain references to html elements
 * @param callback Callback to be called when a click does not target any of the above html elements
 */
export function onClickOutsideOf(watchedRefs: AnyRef<HTMLElement | string | null | undefined>[], callback: () => void) {
  const listener = (event: MouseEvent) => {
    const target = event.target as Element | null
    if (!target) return

    // If none of the refs contain the target, call callback
    if (!watchedRefs.some((watchedRef) => {
      const value = watchedRef.value
      if (typeof value === 'string') {
        // Value is a selector so we need to check if such selector exists in the tree above
        return !!target.closest(value)
      } else if (value) {
        // Value is a HTMLElement and target is contained within
        return value.contains(target)
      } else {
        // Explicitly return false
        return false
      }
    })) {
      callback()
    }
  }

  onMounted(() => {
    window.addEventListener('click', listener, true)
    window.addEventListener('contextmenu', listener, true)
  })

  onBeforeUnmount(() => {
    window.removeEventListener('click', listener, true)
    window.removeEventListener('contextmenu', listener, true)
  })
}

export function useFocus (focusImmediately = false) {
  const targetElement = ref<HTMLElement>()
  const targetElementFocus = () => targetElement.value?.focus()

  if (focusImmediately) {
    onMounted(targetElementFocus)
  }

  return {
    focusElement: targetElement,
    focus: targetElementFocus
  }
}

export function useDeepFocus (focusImmediately = false) {
  const targetElement = ref<HTMLElement>()

  const activeElement = ref<Element | null>(document.activeElement)
  const activeElementUpdate = () => {
    activeElement.value = document.activeElement
  }

  // Listeners
  const blurListener = (event: FocusEvent) => {
    if (event.relatedTarget) return
    if (event.target && targetElement.value && targetElement.value.contains(event.target as Element)) return
    activeElementUpdate()
  }

  const focusListener = activeElementUpdate

  const clickListener = (event: MouseEvent) => {
    const target = event.target as Element | null
    if (!target) return

    const element = targetElement.value
    if (!element) return

    if (!element.contains(target)) focusLostCallback.value?.()
  }

  onMounted(() => {
    const element = targetElement.value
    if (!element) return

    element.addEventListener('blur', blurListener, true)
    element.addEventListener('focus', focusListener, true)

    window.addEventListener('click', clickListener, true)
    window.addEventListener('contextmenu', clickListener, true)
  })

  onBeforeUnmount(() => {
    const element = targetElement.value
    if (!element) return

    element.removeEventListener('blur', blurListener, true)
    element.removeEventListener('focus', focusListener, true)

    window.removeEventListener('click', clickListener, true)
    window.removeEventListener('contextmenu', clickListener, true)
  })

  const inFocus = computed(() => {
    return (targetElement.value && activeElement.value) ? targetElement.value.contains(activeElement.value) : false
  })

  const focusLostCallback = ref<() => void>()

  onMounted(() => {
    if (focusImmediately) {
      targetElement.value?.focus()
    }

    watch(inFocus, (value) => {
      if (value === false && focusLostCallback.value) focusLostCallback.value()
    })
  })

  return {
    focusElement: targetElement,
    focusLost: (onFocusLost: () => void) => {
      focusLostCallback.value = onFocusLost
    }
  }
}